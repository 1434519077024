<style lang="scss" scoped>
	.h-nav{
		position: sticky;
		top: 0;
		z-index: 100;
		background: rgba(0,0,0,0.53);
		backdrop-filter: blur(13px);
		.h-nav-head{
			@include n-row3;
			padding: 0 0.24rem;
			height: 0.6rem;
			position: relative;
			background: rgba(216, 216, 216, 0.17);

			// &::after{
			// 	content: ' ';
			// 	position: absolute;
			// 	bottom: 0;
			// 	left: 0;
			// 	width: 100%;
			// 	height: 1px;
			// 	background: linear-gradient(90deg, rgba(115, 227, 205, 0), rgba(86, 252, 212, 0.3), rgba(63, 194, 158, 0));
			// }
			
		}
		.h-nav-num{
			// width: 1.41rem;
			height: 0.32rem;
			padding: 0 0.26rem;
			font-size: 0.18rem;
			white-space: nowrap;
			color: rgba(242, 211, 255, 1);
			@include n-row1;
			position: relative;
			gap:0.12rem;
			+.h-nav-num::before {
				content: ' ';
				width: 0.01rem;
				height: 0.14rem;
				background: rgba(255,255,255,0.58);
				position: absolute;
				left: 0;

			}
			>*{
				position: relative;
				z-index: 2;
			}
			
			>img{
				height: 0.2rem
			}
			
			
		}
		.h-nav-num2{
			color: rgba(255, 255, 255, 1);
		}
		
		.h-nav-content{
			@include n-row5;
			height: 1rem;
			position: relative;
			border-bottom: 0.01rem solid rgba(255,255,255,0.21);
			
		}
		.h-nav-logo{
			position: absolute;
			left: 1.21rem;
			height: 0.8rem;
		}
		.h-nav-menuBtn{
			height: 100%;
			padding: 0.28rem;
			background: url('@/assets/img/h52/2.png') no-repeat center/ 100% 100%;
		}
		.h-nav-loginBtn{
			height: 0.6rem;
			margin-right: 0.2rem;
		}
		.h-nav-user{
			margin-right: 0.33rem;
			>.h-avatar{
				--size:0.84rem;
			}
			
			.h-nav-user-mask{
				position: absolute;
				left: -200vw;
				width: 100%;
				height: calc(var(--vh) * 100);/*改为*/
				top: 100%;
				background: rgba(0,0,0,0.8);
			}
			.h-nav-user-pop{
				position: absolute;
				top: calc(100% + 0.1rem);
				width: 3.38rem;
				right: 0.1rem;
				transition: all 0.3s;
				transform: translate(150%);
				// background: url('@/assets/img/h5/103.png') no-repeat center/ 100% 100%;
				background: #262626;
				border: 0.01rem solid rgba(255,255,255,0.12);
				.h-nav-user-item{
					@include n-row1;
					height: 1.07rem;
					// font-size: 0.3rem;
					// color: #00FB97;
					font-weight: 400;
					font-size: 0.28rem;
					color: #DADADA;
					gap:0.4rem;
					padding: 0 0.58rem;
					@include n-borderBottom(0.17rem);
					>img{
						height: 0.4rem;
						object-fit: contain;
						width: 0.4rem;
					}
				}
				
			}
			&.h-nav-userPopShow{
				.h-nav-user-mask{
					left: 0;
				}
				.h-nav-user-pop{
					transform: translate(0);
				}
			} 
		}
		.h-nav-pop{
			justify-content: flex-start;
			.n-drawer-content{
				max-height: none;
				width: 5.2rem;
				height: 100%;
				transform: translate(-100%,0);
				background: #262626;
			}
			&.n-drawerShow{
				.n-drawer-content{
					transform: translate(0,0);
				}
				
			}
		}
		.h-nav-popHead{
			height: 1.16rem;
			@include n-row5;
			padding: 0 0.24rem;
			position: relative;
			>img{
				height: 0.9rem;
			}
			>i{
				color: #fff;
				transform: translate(0,-10%);
				font-size: 0.33rem;
			}
			// &::after{
			// 	content: ' ';
			// 	position: absolute;
			// 	bottom: 0;
			// 	left: 0;
			// 	width: 100%;
			// 	height: 1px;
			// 	background: linear-gradient(90deg, rgba(115, 227, 205, 0), rgba(86, 252, 212, 1), rgba(63, 194, 158, 0));
			// }
			// &::before{
			// 	content: ' ';
			// 	position: absolute;
			// 	top:100%;
			// 	width: 1.01rem;
			// 	height: 0.27rem;
			// 	left:0;
			// 	pointer-events: none;
			// 	background: url('@/assets/img/h5/68.png') no-repeat center/ 100% 100%;
			// }
		}
		.h-nav-list{
			margin-top: 0.2rem;
			overflow: hidden scroll;
			height: calc(var(--vh) * 100 - 1.36rem);
			@include n-hideScroll;
			>li{
				@include n-row1;
				gap:0.12rem;
				padding: 0 0.11rem 0 0.28rem;
				position: relative;
				height: 1.06rem;
				font-size: 0.3rem;
				color: rgba(255, 255, 255, 1);
				>img{
					height: 0.4rem;
					width: 0.4rem;
    			margin-right: 0.49rem;
				}
				>i{
					margin-left: auto;
					color: rgba(255,255,255,0.29);
					font-size: 0.4rem;
				}
				
				// &::before,
				&::after{
					content: ' ';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 94%;
					margin: 0 3%;
					height: 1px;
					background: rgba(151,151,151,0.26);
					opacity: 0.45;
					// background: linear-gradient(44deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
				}
				&::before{
					top: 0;
					display: none;
					bottom: auto;
				}
				&:first-child::before{
					display: block;
				}
				
			}
			.h-nav-list-cur{
				z-index: 2;
				margin: -1px;
				color: #FFC300;
				font-weight: 600;
				background: rgba(15, 15, 15, 0.55);
				// background: linear-gradient( 90deg, rgba(255,255,255,0) 0%, rgba(131,255,190,0.29) 48%, rgba(255,255,255,0) 100%);
				>i{
					color: rgba(122, 240, 172, 0.29);
				}
				// &::after,&::before{
				// 	z-index: 2;
				// 	display: block;
				// 	background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(117, 251, 172, 1), rgba(255, 255, 255, 0)) !important;
				// }
				.t-right {
					width: 0.06rem;
					height: 0.36rem;
					background: #FFC300;
				}
			}
		}
		.h-nav-popFooter{
			height: 2.6rem;
			margin: 0 auto;
			display: block;
			opacity: 0.2;
			margin-top: 0.5rem;
		}
		
		.h-nav-adDialog{
			.n-dialog-content{
				>img{
					width: 7.1rem;
				}
				>i{
					height: 0.5rem;
					position: absolute;
					right: 0.1rem;
					top: 1.8rem;
					width: 0.5rem;
				}
			}
		}
		
	}
	
	
</style>

<template>
	<div class="h-nav">
		<div class="h-nav-head" v-if="isLogin">
			<span class="h-nav-num"><img src="@/assets/img/h52/75.png" /><em>
				{{userInfo.secondCurrency || '0.00'}}</em>
				<!-- <img src="@/assets/img/h52/207.png" /> -->
			</span>
			<span class="h-nav-num h-nav-num2"><img src="@/assets/img/h52/26.png" /><em>{{userInfo.userBalance || '0.00'}}</em>
				<img  @click="$router.push('/h5/recharge')" src="@/assets/img/h52/208.png" />
			</span>
		</div>
		
		<article class="h-nav-content">
			<img class="h-nav-menuBtn" src="@/assets/img/h52/1.png" @click="(showMenu = !showMenu, showUserMenu = false)" />
			<img @click="$router.push('/h5/home')" class="h-nav-logo" src="@/assets/img/h52/21.png" />
			<img  class="h-nav-loginBtn" v-if="!isLogin" @click="loginConf.show = true" src="@/assets/img/h52/20.png" />
			<div class="h-nav-user" :class="{'h-nav-userPopShow':showUserMenu }" v-else>
				<div class="h-avatar"><img :src="userInfo.userPic" @click="(showUserMenu = !showUserMenu, showMenu = false)" /></div>
				<div class="h-nav-user-mask" @click="showUserMenu = false"></div>
				<div class=" h-nav-user-pop" >
					<div class="h-nav-user-item" v-for="item in userMenu" :key="item.value" @click="menuClick(item)">
						<img :src="item.img" />
						{{item.label}}
					</div>
				</div>
				
			</div>
		</article>
		 
		<h-login-dialog :conf="loginConf" />
		<h-cdk-dialog :conf="cdkConf" />
		<h-welfare-dialog v-if="welfareConf.show" :welfareConf="welfareConf" />
		
		
		
		<div :class="{'h-nav-pop':1, 'n-drawer':1, 'n-drawerShow': showMenu}">
			<div class="n-drawer-mask" @click="showMenu = false"></div>
			<div class="n-drawer-content">
				<header class="h-nav-popHead"><img @click="(showMenu = false,$router.push('/h5/home'))" src="@/assets/img/h52/21.png" />
					<!-- <i class="n-icon-close" @click="showMenu = false" ></i> -->
				</header>
				
				<ul class="h-nav-list">
					<li :class="{'h-nav-list-cur': $route.path === item.value}" v-for="item in menuList.filter(item=>isShow({item}))" @click="menuClick(item)">
						<img v-if="$route.path === item.value"  :src="item.curImg" />
						<img v-else  :src="item.img" />
						{{item.label}}
						<i class="t-right"></i>
						<!-- <i class="n-icon-t-right"></i> -->
					</li>
				</ul>
				
				<!-- <img class="h-nav-popFooter" src="@/assets/img/h5/24.png" /> -->
			</div>
		</div>
		
		<!-- <div v-if="!isLogin" :class="{'h-nav-adDialog':1,'n-dialog':1,'n-dialogShow': showAd}">
			<div class="n-dialog-mask"></div>
			<div class="n-dialog-content">
				<img @click="(loginConf.show = 1, $store.commit('SET',{k:'showAd',v:false}))" src="@/assets/img/h5/257.png" />
				<i @click="$store.commit('SET',{k:'showAd',v:false})"></i>
			</div>
		</div> -->
		
	</div>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export default{
		name: 'h-nav',
		data(){
			return{
				loginConf:{ show:0},
				cdkConf:{
					show:0
				},
				welfareConf:{
					show:0,
					type: ''
				},
				showMenu:0,
				showUserMenu: false,
				menuList:[
					{ label: '首页', value: '/h5/home', img: require('@/assets/img/h52/home.png'), curImg: require('@/assets/img/h52/homecopy.png') },
					{ label: '升级', value: '/h5/uplevel', img: require('@/assets/img/h52/6.svg'), curImg: require('@/assets/img/h52/6copy.png') },
					{ label: '对战', showFunc: ()=> this.userInfo.battleQdzs === '0',  value: '/h5/battle', img: require('@/assets/img/h52/4.svg'), curImg: require('@/assets/img/h52/4copy.png') },
					{ label: 'Roll房', value: '/h5/roll', img: require('@/assets/img/h52/10.svg'), curImg: require('@/assets/img/h52/10copy.png') },
					{ label: '热门活动', value: '/h5/activity', img: require('@/assets/img/h52/3.svg'), curImg: require('@/assets/img/h52/3copy.png') },
					{ label: '商城', needLogin: true, value: '/h5/store', img: require('@/assets/img/h52/7.svg'), curImg: require('@/assets/img/h52/7copy.png') },
					{ label: 'VIP', needLogin: true, value: '/h5/vip', img: require('@/assets/img/h52/8.svg'), curImg: require('@/assets/img/h52/8copy.png') },
					{ label: '任务', needLogin: true, value: '/h5/task', img: require('@/assets/img/h52/5.svg'), curImg: require('@/assets/img/h52/5copy.png') },
					{ label: 'CDK', needLogin: true, value: 'cdk', img: require('@/assets/img/h52/9.svg'), curImg: require('@/assets/img/h52/9copy.png') },
					{ label: '消费福利', needLogin: true, value: 'xf', img: require('@/assets/img/h5/304.png'), curImg: require('@/assets/img/h5/305.png') },
					{ label: '白给福利', needLogin: true, value: 'bg', img: require('@/assets/img/h5/306.png'), curImg: require('@/assets/img/h5/307.png') },
					// { label: '在线客服', showFunc: ()=> this.isLogin, value: 'customer', img: require('@/assets/img/h5/4.png'), curImg: require('@/assets/img/h5/3.png') },
				],
				userMenu:[
					{ label: '充值', value: '/h5/recharge', img: require('@/assets/img/h52/11.png') },
					{ label: '个人中心', value: '/h5/me', img: require('@/assets/img/h52/12.png') },
					{ label: '我的背包', value: '/h5/knapsack', img: require('@/assets/img/h52/13.png') },
					// { label: 'CDK', value: 'cdk', img: require('@/assets/img/h5/220.png') },
					{ label: '退出登录', value: 'logout', img: require('@/assets/img/h52/14.png') },
				
				]
			}
		},
		computed:{
			isLogin(){
				return this.$store.state.token
			},
			userInfo(){
				return this.$store.state.userInfo || {}
			},
			showAd(){
				return this.$store.state.showAd
			}
		},
		watch:{
			'$route'(r){
				if(r.query._navLogin && !this.isLogin) this.loginConf.show = true;
			}
		},
		mounted(){
		},
		methods:{
			...tool({ keyMap:{ isShow:1} }),
			
			async menuClick({value, needLogin}){
				this.showMenu = this.showUserMenu = false;
				if (needLogin && !this.isLogin) {
					return this.loginConf.show = true
				}
				if(value === 'logout'){
					const res = await new Promise(r=> this.$hMsgDialog.toggle({
						title: '确认您的操作',
						content: `是否退出当前账户？`,
						onConfirm: conf=>r(1,conf.show = false)
					}))
					this.$store.commit('SET_AND_STORAGE',{k: 'userInfo', v: '', encode:0});
					this.$store.commit('SET_AND_STORAGE',{k: 'token', v: '', encode:0});
					this.$hMsg('退出成功')
					this.$router.replace('/h5/home')
				}
				else if(value === 'cdk') this.cdkConf.show = true;
				else if(value === 'xf'){
						if(!this.isLogin) return this.loginConf.show = true
					 this.welfareConf.show = true; 
					 this.welfareConf.type = 'xf';
					}
				else if(value === 'bg') {
					if(!this.isLogin) return this.loginConf.show = true
					this.welfareConf.show = true; 
					this.welfareConf.type = 'bg';
				}
				else if(value === 'customer') {
					window.open('./visiter.html', '_blank')
				}
				else this.$router.push(value);
				
			},
			
		}
		
		
	}
	
</script>
